import {
  Order,
  OrderMethod,
  OrderStatus,
  OrderType,
  PaymentStatus,
} from '@wix/ambassador-pricing-plans-v2-order/types';
import { type PublicPlan, PeriodUnit } from '@wix/ambassador-pricing-plans-v2-plan/types';
import type { FlowAPI } from '@wix/yoshi-flow-editor';

export const DEFAULT_MOCK_PLAN_ID = 'some-id-1';
export const HIGHLIGHTED_MOCK_PLAN_ID = 'some-id-2';

export const demoPlanFixture = (t: FlowAPI['translations']['t']): PublicPlan => ({
  id: 'a138c7e6-83cb-4dd3-b7cd-2c95d3719c54',
  name: 'Beginner',
  description: 'Explore the basics with this introductory course',
  perks: { values: ['6 classes', '3 individual sessions', 'Online resources'] },
  pricing: {
    singlePaymentForDuration: { count: 3, unit: PeriodUnit.MONTH },
    price: { value: '15', currency: 'EUR' },
    feeConfigs: [],
  },
  primary: false,
  slug: 'beginner',
  maxPurchasesPerBuyer: 0,
  allowFutureStartDate: false,
  buyerCanCancel: true,
  termsAndConditions: '',
  clientData: { termsAndConditionsSettings: '{"acceptRequired":false,"isAcceptedByDefault":false}' },
});

export const demoOrderFixture = (t: FlowAPI['translations']['t']): Order => ({
  id: 'b892a566-3089-48af-97d1-667a00bf7982',
  planId: 'a138c7e6-83cb-4dd3-b7cd-2c95d3719c54',
  subscriptionId: '0c7c0896-c48a-4441-9786-6d84ba632a9e',
  wixPayOrderId: '25ed8747-4953-4260-99b2-6cd4f1acc8b1',
  buyer: {
    memberId: '195e399b-e8e0-44c1-a343-932445694c1d',
    contactId: '195e399b-e8e0-44c1-a343-932445694c1d',
  },
  priceDetails: {
    subtotal: '15.00',
    discount: '0',
    total: '15.00',
    planPrice: '15',
    currency: 'EUR',
    singlePaymentForDuration: { count: 3, unit: PeriodUnit.MONTH },
  },
  pricing: {
    singlePaymentForDuration: { count: 3, unit: PeriodUnit.MONTH },
    prices: [
      {
        duration: { cycleFrom: 1, numberOfCycles: 1 },
        price: { subtotal: '15.00', discount: '0', total: '15.00', currency: 'EUR' },
      },
    ],
  },
  type: OrderType.ONLINE,
  orderMethod: OrderMethod.UNKNOWN,
  status: OrderStatus.DRAFT,
  lastPaymentStatus: PaymentStatus.UNPAID,
  pausePeriods: [],
  currentCycle: { index: 1 },
  cycles: [{ index: 1 }],
  planName: 'Beginner',
  planDescription: 'Explore the basics with this introductory course',
  planPrice: '15',
  formData: { submissionData: {} },
  statusNew: OrderStatus.DRAFT,
});

export const plansFixtureTranslated = (t: FlowAPI['translations']['t']): PublicPlan[] => [
  {
    id: DEFAULT_MOCK_PLAN_ID,
    createdDate: new Date('2022-02-22'),
    updatedDate: new Date('2022-02-22'),
    perks: {
      values: [t('demo-plans.first-perk'), t('demo-plans.second-perk'), t('demo-plans.third-perk')],
    },
    description: t('demo-plans.first-plan.description'),
    name: t('demo-plans.first-plan.name'),
    buyerCanCancel: false,
    pricing: {
      freeTrialDays: 0,
      price: {
        value: '15',
        currency: 'USD',
      },
      singlePaymentForDuration: {
        count: 3,
        unit: PeriodUnit.MONTH,
      },
    },
    clientData: {
      coverImage: JSON.stringify({ uri: '3b51c9_e4705f82be3a45b2a1689e9f06d496a4~mv2.jpg', width: 903, height: 512 }),
    },
  },
  {
    id: HIGHLIGHTED_MOCK_PLAN_ID,
    createdDate: new Date('2022-02-22'),
    updatedDate: new Date('2022-02-22'),
    perks: {
      values: [
        t('demo-plans.first-perk'),
        t('demo-plans.second-perk'),
        t('demo-plans.third-perk'),
        t('demo-plans.fourth-perk'),
        t('demo-plans.fifth-perk'),
        t('demo-plans.sixth-perk'),
      ],
    },
    description: t('demo-plans.second-plan.description'),
    name: t('demo-plans.second-plan.name'),
    buyerCanCancel: false,
    pricing: {
      freeTrialDays: 0,
      price: {
        value: '10',
        currency: 'USD',
      },
      subscription: {
        cycleDuration: {
          count: 1,
          unit: PeriodUnit.MONTH,
        },
        cycleCount: 12,
      },
    },
    clientData: {
      coverImage: JSON.stringify({ uri: '3b51c9_262503d50e06460cb618cfbb0a5b0ba8~mv2.jpg', width: 903, height: 512 }),
    },
  },
  {
    id: 'some-id-3',
    createdDate: new Date('2022-02-22'),
    updatedDate: new Date('2022-02-22'),
    perks: {
      values: [
        t('demo-plans.first-perk'),
        t('demo-plans.second-perk'),
        t('demo-plans.third-perk'),
        t('demo-plans.fourth-perk'),
        t('demo-plans.fifth-perk'),
        t('demo-plans.sixth-perk'),
        t('demo-plans.seventh-perk'),
      ],
    },
    description: t('demo-plans.third-plan.description'),
    name: t('demo-plans.third-plan.name'),
    buyerCanCancel: false,
    pricing: {
      freeTrialDays: 0,
      price: {
        value: '30',
        currency: 'USD',
      },
      subscription: {
        cycleDuration: {
          count: 1,
          unit: PeriodUnit.MONTH,
        },
        cycleCount: 0,
      },
    },
    clientData: {
      coverImage: JSON.stringify({ uri: '3b51c9_8e6619299fa64415904d6f351720f461~mv2.jpg', width: 896, height: 508 }),
    },
  },
];
